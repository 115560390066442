import * as firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';

var firebaseConfig = {
  apiKey: "AIzaSyB6O-pdCLYFA9DnrtZV_Z2JJO-5GDFWZH0",
  authDomain: "mtazzz.firebaseapp.com",
  projectId: "mtazzz",
  storageBucket: "mtazzz.appspot.com",
  messagingSenderId: "70788250323",
  appId: "1:70788250323:web:9499316d2bf9b6d96b7226",
  measurementId: "G-4S2QQ22C80"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const projectStorage = firebase.storage();
const projectFirestore = firebase.firestore();
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { projectStorage, projectFirestore, timestamp };